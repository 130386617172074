<!-- banner-->
<!--section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
</section-->
<!-- banner-->
<section class="banner py-3 mt-5" style='background-color: #f5f5f5;'>
  <div class="container">
    <div class='row'>
      <div class="col-sm-7 col-xs-12 banner__content">
        <span style='background-color: transparent'><b>Giải pháp</b>
          <font class='text-orange'> ERP</font><br><font style='font-weight: normal;'>Cho ngành xăng dầu</font>
        </span><font class='text-justify'>
        NABIT-ERP là giải pháp quản trị nguồn lực doanh nghiệp tổng thể cho một doanh nghiệp kinh doanh xăng dầu, quản lý toàn bộ các nghiệp vụ đặc thù về xăng dầu, bao gồm kinh doanh, kế toán, quản lý kho hàng, quản lý cửa hàng, tích hợp các hệ thống tự động hóa xăng dầu</font>
      </div>
      <div class='col-sm-5 col-xs-12'>
        <img class="banner__img" src="./assets/images/Products/erp-newway-pms.png" style="float:right" alt="">
      </div>
    </div>
  </div>
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #fff;">
    <div class="container">
      <div class="row magin-full" style="background-color: white">
        <div class="col-md-12 col-sm-12 col-xs-12 py-5 text-justify" style="font-size:18px">
          <div style='float:left;padding:10px 30px 10px 0px;text-align: center; width:50%;margin-right: 30px;'>
            <!--img src='./assets/images/Products/ERP2.png' style='max-width: 100%;' /><br /-->
            <img src='./assets/images/Products/ERP.jpg' style='max-width: 100%;' /><br />
          </div>
          <h3 class='text-cyan bold-2'>
            &nbsp;Lợi ích khi sử dụng hệ thống
          </h3>
          <p style='padding-right: 20px;'>
            &nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Giúp nâng cao năng lực quản lý và kiểm soát nguồn lực của Công ty từ trung đến
            tận các cửa hàng, tăng tính minh bạch, chủ động trong quản lý hàng hóa và kế hoạch hóa dòng tiền và giảm
            thiểu rủi ro về tài chính.</p>
          <p>&nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Giúp quản trị được mạng lưới bán lẻ trực thuộc. Đơn vị có thể phân tích doanh số, sản lượng, công nợ, tồn quỹ theo từng vùng miền, từng đơn vị, từng cửa hàng, từng khách hàng ... Từ đó xây dựng và quyết định chính sách giá cho từng vùng, miền, đơn vị. </p>
          <p>&nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Kiếm soát sự phân bố của mạng lưới bán lẻ của Công ty. Thông tin phản ánh tức thời, chi tiết đến từng CHXD: tồn kho, doanh số, sản lượng, tồn quỹ, công nợ... Song song với đó là việc phát triển mạng lưới đại lý kinh doanh xăng dầu cho Công ty</p>
          <p>&nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Giải quyết trọn vẹn các bài toán nghiệp vụ, quản lý, kết nối với các hệ thống cổng thông tin doanh nghiệp, hệ thống phát hành hóa đơn điện tử. Sẵn sàng kết nối với các hệ thống tự động hóa tại kho xăng dầu và các cửa hàng xăng dầu đo bồn tự động, thu nhận số liệu cột bơm, thay đổi giá.</p>
          <div class='p-5' style='float:right;padding:10px;text-align: center; width:50%;'>
            <img src='./assets/images/Products/ERP6.jpg' style='max-width: 100%;' /><br />
          </div>
          <h3 class="text-cyan bold-2"><br><br><br><br>
            &nbsp;Các chức năng chính
          </h3>
          <font class='text-orange'>
            &nbsp;&nbsp;<i class='fa fa-check'></i>
            &nbsp;&nbsp;Phân hệ Mua hàng (MM - Material Management): Ghi nhận các giao dịch nhập, mua hàng, quản lý kho theo luồng quy trình từ yêu cầu mua hàng, hợp đồng, đơn đặt mua hàng, nhập mua hàng ...
          </font>
          <ul>
            <li>Quy trình đặt hàng</li>
            <li>Quy trình nhập khẩu</li>
            <li>Quy trình giao nhận hàng hóa</li>
            <li>Quản lý hóa đơn, thanh toán, ngoại tệ</li>
            <li>Quản lý các unit costs của hàng hóa</li>
            <li>Các quy trình quản lý về lượng và giá trị hàng hóa tại kho</li>
            <li>Hệ thống báo cáo mua hàng</li>
          </ul>
          <div style='float:left;padding:10px;text-align: center; width:50%;'>
            <img src='./assets/images/Products/ERP4.png' style='max-width: 100%;' /><br />
          </div>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;&nbsp;Phân hệ Bán hàng (SD - Sale Distribution): Ghi nhận các giao dịch xuất, bán hàng theo luồng quy trình từ hợp đồng, đơn đặt hàng, xuất hàng, hóa đơn... Dữ liệu được tích hợp với phân hệ kế toán công nợ phải thu AR.
          </font>
          <ul>
            <li>Quản lý giá bán</li>
            <li>Quản lý đơn đặt hàng</li>
            <li>Quản lý khách hàng</li>
            <li>Quy trình giao nhận hàng hóa</li>
            <li>Quy trình bán hàng gửi</li>
            <li>Quản lý liên kết với phân hệ MM</li>
            <li>Quản lý hóa đơn, hạn mức công nợ</li>
            <li>Hệ thống báo cáo bán hàng</li>
          </ul>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;&nbsp;Phân hệ Quản lý kho (WM - Warehouse Management)
          </font>
          <ul>
            <li>Quản lý nhập - xuất - tồn hàng hóa</li>
            <li>Kiểm kê hàng hóa đột xuất, theo kỳ</li>
            <li>Trị giá hàng tồn kho theo các phương pháp: FIFO/Moving Average/Standard  đã được thiết lập cho từng Material</li>
            <li>Hệ thống báo cáo về tồn kho, nhà cung cấp, đơn hàng, danh sách báo giá, báo cáo hàng đang đi đường ...</li>
            <li>Sắn sàng cho việc Tích hợp hệ thống Tựu động hóa tại các kho hàng: Đo bồn tự động, tự động hóa nhập hàng, tự động hóa bến xuất, hệ thống xuất hàng qua xe tra nạp, xuất hàng qua hệ thống tra nạp ngầm.</li>
          </ul>
          <div style='float:right;padding:10px;text-align: center; width:50%;'>
            <img src='./assets/images/Products/ERP3.png' style='max-width: 100%;' />
          </div>
          <div class='p-3'>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;&nbsp;Phân hệ Kế toán tài chính (FI - Financial): Cung cấp bức tranh toàn cảnh về tình hình tài chính của doanh nghiệp. Phân hệ Tài chính kế toán gồm phân hệ sổ cái tổng hợp GL, và các sổ phụ:
          </font>
          <ul>
            <li>Công nợ phải trả</li>
            <li>Công nợ phải thu</li>
            <li>Kế toán tiền mặt</li>
            <li>Kế toán ngân hàng</li>
            <li>Kế toán ngoại tệ.</li>
            <li>Kế toán tài sản cố định.</li>
            <li>Hệ thống báo cáo kế toán tài chính theo chuẩn mực nhà nước.</li>
          </ul></div>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;&nbsp;Phân hệ Kế toán quản trị (CO - Controlling): Cung cấp thông tin quản trị để hỗ trợ ra quyết định bao gồm quản lý chi phí, lợi nhuận
          </font>
          <ul>
            <li>Kế toán trung tâm chi phí (Cost Center)</li>
            <li>Kế toán trung tâm lợi nhuận (Profit Center)</li>
            <li>Kế toán quản trị và phân bổ chi phí chung</li>
            <li>Kế toán các khoản chi phí và doanh thu nội bộ giữa các đơn vị</li>
            <li>Hệ thống báo cáo quản trị.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
