import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html'
})
export class StructureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
