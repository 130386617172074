<div class="msp-item-card-container">
  <div class="list-item-container custom-scroll" num="2" style="max-width:1500px">
    <!--START-->
    <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1 bc-blue" module="ItemCardModule">
      <div class="msp-item-card">
        <div class="msp-item-name">Standard</div>
        <div class="under-line bg-blue"></div>
        <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Gói đầy đủ các tính năng quản lý hệ thống kho xăng dầu</small></div>
        <div class="msp-btn-buy bg-blue" routerLink="/ContactUs">Liên hệ</div>
        <div class="msp-item-detail">
          <div class="msp-view-detail">
            <div class="item-detail">
              <div class="item-text text-blue"><b>Quản lý danh mục dùng chung</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý danh mục bồn, bể, họng xuất, họng nhập, seal niêm phong</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý danh mục hàng hóa, phương tiện vận tải thủy, phương tiện vận tải bộ - chi tiết đến từng hầm hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý danh mục định mức hao hụt nhập, xuất, tồn chứa, pha chế, chuyển bể</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý nhập hàng hóa</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Nhập hàng đường thủy</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Nhập hàng đường bộ</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý các hoạt động nội bộ trong kho</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Chuyển bể nội bộ kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Pha chế hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Đo bể hàng ngày</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý xuất hàng hóa</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Xuất hàng đường bộ: viết phiếu xuất, hỗ trợ gieo hầm, xuất hàng, biên bản xuất hàng, phiếu xuất kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Xuất hàng đường thủy: viết phiếu xuất, xuất hàng, biên bản xuất hàng, phiếu xuất kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý/đối chiếu chi phí thuê kho, lưu kho</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Cập nhật đơn giá, chi phí thuê kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Theo dõi chi tiết lượng xuất bán cho khách, theo mặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Tính toán, tổng hợp chi phí thuê kho, đối chiếu với khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống kinh doanh xăng dầu</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Nhận lệnh nhập, xuất trực tiếp từ hệ thống kinh doanh</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Trả số lượng xuất thực tế cho từng lệnh xuất</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text-delete">Tích hợp hệ thống PXK điện tử</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp hệ thống tự động hóa bến xuất</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp hệ thống tự động hóa đo bồn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Báo cáo/bảng kê</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Bảng kê/báo cáo nhập hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Bảng kê/báo cáo xuất hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Thẻ kho hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Thẻ bể</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo Nhập - Xuất - Tồn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Đối chiếu lượng xuất hàng - theo khách hàng(đơn vị), theo mặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo hàng gửi của khách hàng tại kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo tổng hợp chi phí thuê kho theo khách</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Bảng kê tính toán/đối chiếu chi phí thuê kho chi tiết theo khách/mặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo hao hụt công đoạn/tổng hợp – thực tế và định mức</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END-->
    <!--START-->
    <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1 bc-orange" module="ItemCardModule">
      <div style="height: 32px; width: 100%" objname="jSuggestItem" class="show">
        <div class="icon-star-white package-suggest bg-orange">
            Gói khuyên dùng
        </div>
      </div>
      <div class="msp-item-card">
        <div class="msp-item-name">Standard</div>
        <div class="under-line bg-orange"></div>
        <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Gói đầy đủ các tính năng quản lý hệ thống kho xăng dầu. Tích hợp hệ thống tự động hóa</small></div>
        <div class="msp-btn-buy bg-orange" routerLink="/ContactUs">Liên hệ</div>
        <div class="msp-item-detail">
          <div class="msp-view-detail">
            <div class="item-detail">
              <div class="item-text text-orange"><b>Quản lý danh mục dùng chung</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý danh mục bồn, bể, họng xuất, họng nhập, seal niêm phong</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý danh mục hàng hóa, phương tiện vận tải thủy, phương tiện vận tải bộ - chi tiết đến từng hầm hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý danh mục định mức hao hụt nhập, xuất, tồn chứa, pha chế, chuyển bể</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý nhập hàng hóa</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Nhập hàng đường thủy</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Nhập hàng đường bộ</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý các hoạt động nội bộ trong kho</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Chuyển bể nội bộ kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Pha chế hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Đo bể hàng ngày</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý xuất hàng hóa</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Xuất hàng đường bộ: viết phiếu xuất, hỗ trợ gieo hầm, xuất hàng, biên bản xuất hàng, phiếu xuất kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Xuất hàng đường thủy: viết phiếu xuất, xuất hàng, biên bản xuất hàng, phiếu xuất kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý/đối chiếu chi phí thuê kho, lưu kho</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Cập nhật đơn giá, chi phí thuê kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Theo dõi chi tiết lượng xuất bán cho khách, theo mặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tính toán, tổng hợp chi phí thuê kho, đối chiếu với khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống kinh doanh xăng dầu</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Nhận lệnh nhập, xuất trực tiếp từ hệ thống kinh doanh</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Trả số lượng xuất thực tế cho từng lệnh xuất</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp hệ thống PXK điện tử</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp hệ thống tự động hóa bến xuất</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp hệ thống tự động hóa đo bồn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Báo cáo/bảng kê</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Bảng kê/báo cáo nhập hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Bảng kê/báo cáo xuất hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Thẻ kho hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Thẻ bể</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo Nhập - Xuất - Tồn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Đối chiếu lượng xuất hàng - theo khách hàng(đơn vị), theo mặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo hàng gửi của khách hàng tại kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo tổng hợp chi phí thuê kho theo khách</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Bảng kê tính toán/đối chiếu chi phí thuê kho chi tiết theo khách/mặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo hao hụt công đoạn/tổng hợp – thực tế và định mức</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END-->
  </div>
</div>
