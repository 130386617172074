<div class="msp-item-card-container">
  <div class="list-item-container custom-scroll" num="3" style="max-width:1500px">
    <!--START-->
    <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1 bc-blue" module="ItemCardModule"
      style="border-bottom-color: rgb(59, 80, 223);">
      <div class="msp-item-card">
        <div class="msp-item-name">Basic</div>
        <div class="under-line bg-blue"></div>
        <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Đáp ứng các tính năng cơ bản của hệ thống xăng dầu bán buôn</small></div>
        <div class="msp-btn-buy bg-blue" routerLink="/ContactUs">Liên hệ</div>
        <div class="msp-item-detail">
          <div class="msp-view-detail">
            <div class="item-detail">
              <div class="item-text"><b>Quản lý kế hoạch phân nguồn</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý kế hoạch năm</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý kế hoạch quý</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý kế hoạch tháng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo kế hoạch nguồn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý giá</b> </div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quản lý giá nhập</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý giá bán</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo về giá</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý nhập hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Nhập hàng từ Tổng công ty</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Nhập hàng từ đơn vị ngang cấp</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Nhập hàng từ nguồn ngoài</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Nhập khác</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Hóa đơn nhập</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý xuất hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý đơn hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quy trình giao hàng tại kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quy trình giao hàng tại khách</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quy trình xuất nội bộ</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Xuất khác</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý hóa đơn xuất</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quy trình xuất hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quy trình điều chỉnh hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quy trình hủy hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý tiền hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý thông tin ngân hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quy trình thu tiền từ khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Chứng từ kế toán khác</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Sổ sách/báo cáo liên quan</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Giá vốn hàng hóa</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Cấu hình các cách tính giá vốn</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Giá vốn toàn Công ty</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Giá vốn theo kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Báo cáo/bảng kê</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo nhập hàng, nguồn hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo theo lượng xuất bán</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo theo lượng xuất hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo vận tải</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo lãi lỗ/giá vốn toàn C.Ty</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo lãi lỗ/giá vốn theo kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp hệ thống PXK điện tử</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp hệ thống hóa đơn điện tử</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống Portal khách hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Khách hàng đặt hàng qua hệ thống</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Khách hàng quản lý các chuyến hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo, đối chiếu với khách hàng</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END-->
    <!--START-->
    <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1 bc-orange" module="ItemCardModule">
      <div style="height: 32px; width: 100%" objname="jSuggestItem" class="show">
        <div class="icon-star-white package-suggest bg-orange">
            Gói khuyên dùng
        </div>
      </div>
      <div class="msp-item-card">
        <div class="msp-item-name">Standard</div>
        <div class="under-line bg-orange"></div>
        <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Đẩy đủ các tính năng của một hệ thống quản lý bán buôn xăng dầu</small></div>
        <div class="msp-btn-buy bg-orange" routerLink="/ContactUs">Liên hệ</div>
        <div class="msp-item-detail">
          <div class="msp-view-detail">
            <div class="item-detail">
              <div class="item-text"><b>Quản lý kế hoạch phân nguồn</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý kế hoạch năm</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý kế hoạch quý</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý kế hoạch tháng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo kế hoạch nguồn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý giá</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý giá nhập</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý giá bán</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo về giá</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý nhập hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Nhập hàng từ Tổng công ty</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Nhập hàng từ đơn vị ngang cấp</div>
            </div>
            <div class="item-detail">
              <div class="item-text">Nhập hàng từ nguồn ngoài</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Nhập khác</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Hóa đơn nhập</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý xuất hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý đơn hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình giao hàng tại kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình giao hàng tại khách</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình xuất nội bộ</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Xuất khác</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý hóa đơn xuất</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình xuất hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình điều chỉnh hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình hủy hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý tiền hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý thông tin ngân hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình thu tiền từ khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Chứng từ kế toán khác</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Sổ sách/báo cáo liên quan</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Giá vốn hàng hóa</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Cấu hình các cách tính giá vốn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Giá vốn toàn Công ty</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Giá vốn theo kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Báo cáo/bảng kê</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo nhập hàng, nguồn hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo theo lượng xuất bán</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo xuất bán theo hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo vận tải</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo lãi lỗ/giá vốn toàn C.Ty</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Báo cáo lãi lỗ/giá vốn theo kho</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp hệ thống hóa đơn điện tử</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp hệ thống PXK điện tử</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống Portal khách hàng</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Khách hàng đặt hàng qua hệ thống</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Khách hàng quản lý các chuyến hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo, đối chiếu với khách hàng</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END-->
        <!--START-->
    <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1 bc-blue" module="ItemCardModule"
    style="border-bottom-color: rgb(59, 80, 223);">
    <div class="msp-item-card">
      <div class="msp-item-name">Pro</div>
      <div class="under-line bg-blue"></div>
      <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Đẩy đủ các tính năng của một hệ thống quản lý bán buôn xăng dầu. Tích hợp hệ thống portal khách hàng</small></div>
      <div class="msp-btn-buy bg-blue" routerLink="/ContactUs">Liên hệ</div>
      <div class="msp-item-detail">
        <div class="msp-view-detail">
          <div class="item-detail">
            <div class="item-text"><b>Quản lý kế hoạch phân nguồn</b> </div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý kế hoạch năm</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý kế hoạch quý</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý kế hoạch tháng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo kế hoạch nguồn</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Quản lý giá</b> </div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý giá nhập</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý giá bán</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo về giá</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Quản lý nhập hàng</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Nhập hàng từ Tổng công ty</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Nhập hàng từ đơn vị ngang cấp</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Nhập hàng từ nguồn ngoài</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Nhập khác</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Hóa đơn nhập</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Quản lý xuất hàng</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý đơn hàng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình giao hàng tại kho</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình giao hàng tại khách</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình xuất nội bộ</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Xuất khác</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Quản lý hóa đơn xuất</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình xuất hóa đơn</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình điều chỉnh hóa đơn</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình hủy hóa đơn</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Quản lý tiền hàng</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quản lý thông tin ngân hàng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Quy trình thu tiền từ khách hàng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Chứng từ kế toán khác</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Sổ sách/báo cáo liên quan</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Giá vốn hàng hóa</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Cấu hình các cách tính giá vốn</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Giá vốn toàn Công ty</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Giá vốn theo kho</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Báo cáo/bảng kê</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo khách hàng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo nhập hàng, nguồn hàng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo theo lượng xuất bán</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo theo lượng xuất hóa đơn</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo vận tải</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo lãi lỗ/giá vốn toàn C.Ty</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo lãi lỗ/giá vốn theo kho</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Tích hợp hệ thống</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Tích hợp hệ thống PXK điện tử</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Tích hợp hệ thống hóa đơn điện tử</div>
          </div>
          <div class="item-detail">
            <div class="item-text"><b>Tích hợp hệ thống Portal khách hàng</b></div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Khách hàng đặt hàng qua hệ thống</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Khách hàng quản lý các chuyến hàng</div>
          </div>
          <div class="item-detail">
            <div class="icon-item bc-blue"></div>
            <div class="item-text">Báo cáo, đối chiếu với khách hàng</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--END-->
  </div>
</div>
