import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxMarqueeModule } from 'ngx-marquee';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './_Shared/header.component';
import { BannerComponent } from './_Shared/banner.component';
import { FooterComponent } from './_Shared/footer.component';
import { PartnerComponent } from './pages/Home/partner.component';
import { AboutNavleftComponent } from './_Shared/about-navleft.component';
import { ProductsComponent } from './pages/Home/products.component';
import { TechnologyComponent } from './pages/Home/technology.component';
import { CustomersComponent } from './pages/Home/customers.component';
import { HomeComponent } from './pages/Home/home.component';
import { AboutComponent } from './pages/AboutUs/about.component';
import { VisionComponent } from './pages/AboutUs/vision.component';
import { StructureComponent } from './pages/AboutUs/structure.component';
import { PolicyComponent } from './pages/AboutUs/policy.component';
import { PhilosophyComponent } from './pages/AboutUs/philosophy.component';
import { PriceComponent } from './pages/Price/price.component';
import { SupportComponent } from './pages/Support/Support.component';
import { ContactUsComponent } from './pages/ContactUs/ContactUs.component';
import { ERPComponent } from './pages/Products/ERP.component';
import { QLCHComponent } from './pages/Products/QLCH.component';
import { KDXDComponent } from './pages/Products/KDXD.component';
import { QLKhoComponent } from './pages/Products/QLKho.component';
import { PriceERPComponent } from './pages/Price/PriceERP.component';
import { PriceQLCHComponent } from './pages/Price/PriceQLCH.component';
import { PriceKDXDComponent } from './pages/Price/PriceKDXD.component';
import { PriceQLKhoComponent } from './pages/Price/PriceQLKho.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    PartnerComponent,
    FooterComponent,
    ProductsComponent,
    TechnologyComponent,
    CustomersComponent,
    HomeComponent,
    AboutComponent,
    VisionComponent,
    AboutNavleftComponent,
    StructureComponent,
    PolicyComponent,
    PhilosophyComponent,
    PriceComponent,
    SupportComponent,
    ContactUsComponent,
    ERPComponent,
    QLCHComponent,
    KDXDComponent,
    QLKhoComponent,
    PriceERPComponent,
    PriceKDXDComponent,
    PriceQLCHComponent,
    PriceQLKhoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxMarqueeModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
