import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-PriceERP',
  templateUrl: './PriceERP.component.html'
})
export class PriceERPComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
