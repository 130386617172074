<!-- banner-->
<!--section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
</section-->
<!-- banner-->
<section class="banner py-3 mt-5" style='background-color: #f5f5f5;'>
  <div class="container">
    <div class='row'>
      <div class="col-sm-7 col-xs-12 banner__content">
        <span style='background-color: transparent'><b>Giải pháp QUẢN LÝ </b><br>
          <font class='text-orange'> KHO XĂNG DẦU</font>
        </span><font class='text-justify'>
        Giải pháp đặc thủ cho kho xăng dầu, quản lý toàn bộ quá trình nhập xuất hàng hóa tại kho xăng dầu, phân và quản lý nguồn hàng của các đơn vị kinh doanh xăng dầu. Hệ thống sẵn sàng tích hợp với các hệ thống tự động hóa xăng dầu tại kho: Hệ thống tự động hóa bến xuất, hệ thống tự động hóa đo bồn.</font>
      </div>
      <div class='col-sm-5 col-xs-12'>
        <img class="banner__img" src="./assets/images/Products/QLKho.png" style="float:right" alt="">
      </div>
    </div>
  </div>
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #fff; padding: 10px;">
    <div class="container">
      <div class="row magin-full" style="background-color: white">
        <div class="col-md-12 col-sm-12 col-xs-12 py-5 text-justify" style="font-size:18px">
          <div class='pr-5' style='float:left;padding:10px;text-align: center; width:50%;'>
            <!--img src='./assets/images/Products/ERP2.png' style='max-width: 100%;' /><br /-->
            <img src='./assets/images/Products/Mo hinh 5-2.jpg' style='max-width: 100%;' /><br />
          </div>
          <h3 class='text-cyan bold-2'>
            &nbsp;Lợi ích khi sử dụng hệ thống
          </h3>
          <p style='padding-right: 20px;'>
            &nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Quản lý toàn bộ hoạt động nhập xuất và hàng hóa tại kho xăng dầu theo quy trình chuẩn của các đơn vị hàng đầu tại Việt Nam (Petrolimex, PVOIL< PETEC ...)
          </p>
          <p>&nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Quản lý hàng hóa tại kho, cũng như nguồn hàng của các đơn vị gửi hàng tại kho; đối chiếu hàng hóa với các đơn vị; tính phí lưu kho. </p>
          <p>&nbsp;&nbsp;<i class='fa fa-check text-orange'></i>&nbsp;&nbsp;Tích hợp với hệ thống tự động hóa bến xuất, hệ thống tự động hóa đo bồn thành một hệ thống quản lý hoàn chỉnh</p>
          <h3 class="text-cyan bold-2">
            &nbsp;Các chức năng của hệ thống
          </h3>
          <font class='text-orange'>
            &nbsp;&nbsp;<i class='fa fa-check'></i>
            &nbsp;&nbsp;Quản lý nhập hàng:
          </font>
          <ul>
            <li>Quản lý quá trình nhập hàng vào kho theo lệnh của Công ty</li>
            <li>Quản lý tồn kho thực tế theo từng bể hàng hóa tại mỗi lần đo</li>
            <li>Tích hợp dữ liệu tự động hóa để đảm bảo việc đo tính tồn kho tại các bể là khách quan, không phụ thuộc vào con người.</li>
          </ul>
          <div class='ml-5' style='float:right;padding:10px;text-align: center; width:50%;'>
            <img src='./assets/images/Products/Kho2.png' style='max-width: 100%;' />
          </div>
          <font class='text-orange'>
            &nbsp;&nbsp;<i class='fa fa-check'></i>
            &nbsp;&nbsp;Quản lý xuất hàng:
          </font>
          <ul>
            <li>Quản lý lệnh xuất hàng</li>
            <li>Quản lý hầm hàng, hỗ trợ phân hầm tự động</li>
            <li>Quản lý xuất hàng, cập nhật thông tin xuất hàng tự động/bán tự động</li>
            <li>Tích hợp tự động hóa bơm ngắt, lượng thực xuất được cài đặt và đóng ngắt tự động. Nhiệt độ và thể tích L15 tự động được tính trên hệ thống</li>
            <li>PHát hành phiếu xuất kho/phiếu xuất kho điện tử (module tích phiếu xuất kho điện tử)</li>
          </ul>
          <font class='text-orange'>
            &nbsp;&nbsp;<i class='fa fa-check'></i>
            &nbsp;&nbsp;Quản lý các nghiệp vụ nội bộ kho
          </font>
          <ul>
            <li>Nghiệp vụ pha chế, súc rửa, chuyển bể...</li>
            <li>Quản lý hao hụt trong tất cả các công đoạn của kho xăng dầu</li>
            <li>Quản lý kiểm kê hàng hóa</li>
            <li>Tích hợp hệ thống TĐH kho xăng dầu: Tự động hóa bến xuất, tự động hóa đo bồn</li>
            <li>Tích hợp hệ thống HĐĐT, PXK điện tử</li>
            <li>Sẵn sàng tích hợp hệ thống kế toán, hệ thống quản lý của khách hàng</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
