<!-- partner-->
<section class="section pb-5">
  <div class="section__header">
    <div class="container">
      <h2 class="section__heading mb-0">Khách hàng & Đối tác</h2>
    </div>
  </div>
  <div class="container" id='partner'>
    <!-- <ngx-marquee duration="40s">
      <mark>
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL Cai Lan.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL Ha Noi.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL Thai Binh.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL_SaiGon.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Petromekong.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL_HaiPhong.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL_NinhBinh.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL_PhuTho.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Logo_PVOIL_PhuYen.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/Petrolimex.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="https://hilo.com.vn/wp-content/uploads/2018/11/hilo-group-logo-retina.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/VietCis logo.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/peco.jpg" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/NamDuong.jpg" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/HaiBinh.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/IBM_logo.png" alt="">
      <img style='max-height:120px;padding-right:10px' src="./assets/images/Partner/logo microsoft.jpg" alt="">
    </mark>
    </ngx-marquee> -->
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL Cai Lan.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL Ha Noi.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL Thai Binh.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_SaiGon.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Petromekong.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_HaiPhong.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_NinhBinh.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_PhuTho.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_PhuYen.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Petrolimex.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Hilo.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/VietCis logo.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/peco.jpg" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/NamDuong.jpg" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/HaiBinh.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/IBM_logo.png" alt="">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <img src="./assets/images/Partner/logo microsoft.jpg" alt="">
      </ng-template>
    </owl-carousel-o>
    <div class="row">
      <!--div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL Cai Lan.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL Ha Noi.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL Thai Binh.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_SaiGon.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Petromekong.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_HaiPhong.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_NinhBinh.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_PhuTho.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Logo_PVOIL_PhuYen.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/Petrolimex.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="https://hilo.com.vn/wp-content/uploads/2018/11/hilo-group-logo-retina.png" alt="">
        </a>
      </div>
      <div class="col-2 py-3">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/VietCis logo.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/peco.jpg" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/NamDuong.jpg" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/HaiBinh.png" alt="">
        </a>
      </div>
      <div class="col-2">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/IBM_logo.png" alt="">
        </a>
      </div>
      <div class="col-2 py-3">
        <a class="partner" href="#!">
          <img src="./assets/images/Partner/logo microsoft.jpg" alt="">
        </a>
      </div-->
    </div>
  </div>
</section>
