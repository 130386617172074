<!-- header-->
<header class="header">
  <div class="container">
    <div class="media align-items-center position-relative">
      <div class="media-body d-xl-none"></div>
      <a class="logo" routerLink="#!">
        <img src="./assets/images/NABIT_Logo.png" alt="" />
      </a>
      <span style="font-size: 20px">&nbsp;Nabit.vn</span>
      <button class="menu-btn d-xl-none js-mb-menu-btn">
        <i class="fa fa-bars"></i>
      </button>
      <div class="media-body">
        <div class="mb-filter js-mb-filter"></div>
        <div class="mb-menu js-mb-menu">
          <div class="px-20 mt-3 d-xl-none">
            <button class="btn btn-sm btn-dark btn-block js-mb-menu-close">
              <i class="fa fa-long-arrow-left"></i>
              <span>Trở lại</span>
            </button>
          </div>
          <ul class="menu">
            <li class="menu__item">
              <a class="menu__link" routerLink="/">Trang chủ</a>
            </li>
            <li class="menu__item">
              <a class="menu__link" routerLink="/About">Giới thiệu</a>
            </li>
            <li class="menu__item h-dropdown xl-none">
              <a class="menu__link">Sản phẩm&nbsp;<i class="fa fa-angle-down" aria-hidden="true"></i></a>
              <span class="h-dropdown__toggle js-dropdown-toggle"></span>
              <div class="h-dropdown__menu js-dropdown-menu d-xl-none">
                <a class="h-dropdown__item" routerLink="/Product/ERP">ERP xăng dầu</a>
                <a class="h-dropdown__item" routerLink="/Product/QLCH">Quản lý cửa hàng</a>
                <a class="h-dropdown__item" routerLink="/Product/KDXD">Kinh doanh xăng dầu</a>
                <a class="h-dropdown__item" routerLink="/Product/QLKho">Quản lý kho</a>
              </div>
              <div class="h-dropdown__menu xl-none">
                <div class="row">
                  <div class='col-3'>
                    <a class="h-dropdown__item" href="/Product/ERP" style="color:orange;font-weight:900;">
                      ERP XĂNG DẦU
                    </a>
                    <div style='padding:0px 20px;color:gray'>
                      <small>Giải pháp quản trị nguồn lực tổng thể cho doanh nghiệp KDXD</small>
                      <hr />
                    </div>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/ERP">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/MM.png" class='rounded' />
                      </span>
                      <div class='submenu_text'>
                        Material Management<br>
                        <font style='font-size:13px'>Phân hệ mua hàng</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/ERP">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/SD.png" />
                      </span>
                      <div class='submenu_text'>
                        Sale Distribution<br>
                        <font style='font-size:13px'>Phân hệ bán hàng</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/ERP">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/WM.png" />
                      </span>
                      <div class='submenu_text'>
                        Warehouse Management<br>
                        <font style='font-size:13px'>Phân hệ quản lý kho</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/ERP">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/FI.png" />
                      </span>
                      <div class='submenu_text'>
                        Financial<br>
                        <font style='font-size:13px'>Phân hệ kế toán tài chính</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/ERP">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/CO.png" />
                      </span>
                      <div class='submenu_text'>
                        Controlling<br>
                        <font style='font-size:13px'>Phân hệ kế toán quản trị</font>
                      </div>
                    </a>
                  </div>
                  <div class='col-3'>
                    <a class="h-dropdown__item" href="/Product/QLCH" style="color:green;font-weight:900;">
                      Quản lý cửa hàng
                    </a>
                    <div style='padding:0px 20px;color:gray'>
                      <small>Giải pháp quản lý hệ thống Cửa hàng xăng dầu cho đơn vị</small>
                      <hr />
                    </div>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLCH">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/MM.png" class='rounded' />
                      </span>
                      <div class='submenu_text'>
                        Quản lý hàng hóa<br>
                        <font style='font-size:13px'>Quản lý ca bán hàng và hàng hóa</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLCH">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/SD.png" />
                      </span>
                      <div class='submenu_text'>
                        Quản lý tiền hàng<br>
                        <font style='font-size:13px'>Quản lý tiền tại cửa hàng</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLCH">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/WM.png" />
                      </span>
                      <div class='submenu_text'>
                        Tích hợp hệ thống<br>
                        <font style='font-size:13px'>Tích hợp hệ thống TĐH, hệ thống HĐĐT</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLCH">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/FI.png" />
                      </span>
                      <div class='submenu_text'>
                        Quản trị tập trung<br>
                        <font style='font-size:13px'>Tổng hợp số liệu tại Công ty</font>
                      </div>
                    </a>
                  </div>
                  <div class='col-3'>
                    <a class="h-dropdown__item" href="/Product/KDXD" style="color:brown;font-weight:900;">
                      Kinh doanh xăng dầu
                    </a>
                    <div style='padding:0px 20px;color:gray'>
                      <small>Giải pháp quản lý các nghiệp vụ kinh doanh bán buôn xăng dầu</small>
                      <hr />
                    </div>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/KDXD">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/MM.png" class='rounded' />
                      </span>
                      <div class='submenu_text'>
                        Quản lý nhập hàng<br>
                        <font style='font-size:13px'>Quản lý nghiệp vụ nhập hàng</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/KDXD">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/SD.png" />
                      </span>
                      <div class='submenu_text'>
                        Xuất hàng và công nợ<br>
                        <font style='font-size:13px'>Quản lý nghiệp vụ xuất bán</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/KDXD">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/WM.png" />
                      </span>
                      <div class='submenu_text'>
                        Tích hợp hệ thống<br>
                        <font style='font-size:13px'>Tích hợp hệ thống TĐH, hệ thống HĐĐT</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/KDXD">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/FI.png" />
                      </span>
                      <div class='submenu_text'>
                        Quản trị tập trung<br>
                        <font style='font-size:13px'>Tổng hợp số liệu tại Công ty</font>
                      </div>
                    </a>
                  </div>
                  <div class='col-3'>
                    <a class="h-dropdown__item" href="/Product/QLKho" style="color:blue;font-weight:900;">
                      Quản lý kho
                    </a>
                    <div style='padding:0px 20px;color:gray'>
                      <small>Giải pháp lý kho xăng dầu, tích hợp với hệ thống tự đông khóa kho XD</small>
                      <hr />
                    </div>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLKho">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/MM.png" class='rounded' />
                      </span>
                      <div class='submenu_text'>
                        Quản lý nhập hàng<br>
                        <font style='font-size:13px'>Quản lý nghiệp vụ nhập hàng</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLKho">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/SD.png" />
                      </span>
                      <div class='submenu_text'>
                        Xuất hàng và công nợ<br>
                        <font style='font-size:13px'>Quản lý nghiệp vụ xuất bán</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLKho">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/WM.png" />
                      </span>
                      <div class='submenu_text'>
                        Tích hợp hệ thống<br>
                        <font style='font-size:13px'>Tích hợp hệ thống TĐH, hệ thống HĐĐT</font>
                      </div>
                    </a>
                    <a class="h-dropdown__item" style='font-size: 14px' href="/Product/QLKho">
                      <span class="submenu_icon">
                        <img src="./assets/images/icons/FI.png" />
                      </span>
                      <div class='submenu_text'>
                        Quản trị tập trung<br>
                        <font style='font-size:13px'>Tổng hợp số liệu tại Công ty</font>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li class="menu__item">
              <a class="menu__link" routerLink="/Price">Bảng giá</a>
            </li>
            <!--li class="menu__item">
              <a class="menu__link" routerLink="/Support">Hỗ trợ</a>
            </li-->
            <li class="menu__item">
              <a class="menu__link" routerLink="/ContactUs">Liên hệ</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
