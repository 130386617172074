<!-- banner-->
<!--section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
</section-->
<!-- banner-->
<section class="banner py-3 mt-5" style='background-color: #f5f5f5;'>
    <div class="container">
        <div class='row'>
            <div class="col-sm-6 col-xs-12 banner__content">
                <span style='background-color: transparent;font-weight: bold;'><b>Liên hệ với </b>
                    <font class='text-orange'> NABIT</font>
                </span>
                <font class='text-justify'>Vui lòng điền thông tin và gửi về cho chúng tôi.<br>Chúng tôi sẽ phản hồi sớm nhất có thể!</font>
            </div>
            <div class='col-sm-6 col-xs-12'>
                <img class="banner__img" src="./assets/images/Products/ContactUs.png" style="float:right" alt="">
            </div>
        </div>
    </div>
</section>
<section id="new">
    <div class="wrapper not-index" style="background-color: #f5f5f5; padding: 10px;">
        <div class="container">
            <div class="row magin-full shadow-5 p-5" style="background-color: white">
                <div class='col-6'>
                    <img class="img-responsive" style="float: right;" src="./assets/images/Support/ContactUs.png" />
                </div>
                <div class='col-6'>
                    <h3 class='text-cyan bold-2'>
                        &nbsp;Vui lòng điền thông tin<br>
                    </h3>
                    <form>
                        <div class="form-group">
                          <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Họ và tên">
                        </div>
                        <div class="form-group">
                          <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Số điện thoại">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Email">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Chọn sản phẩm">
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" id="formGroupExampleInput2" placeholder="Chi tiết yêu cầu"></textarea> 
                        </div>
                        <div class="form-group">
                            <button class='btn btn-success'>Gửi thông tin</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>