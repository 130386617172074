<!-- banner-->
<section class="banner">
  <img class="banner__img" src="./assets/images/Banner2.jpg" alt="">
  <div class="banner__float">
      <div class="container">
          <div class="banner__content" style="align-items: center;">
              <!--img src='./assets/images/NABIT_Logo.png' style='max-width:80px;'-->
              <span>Công ty cổ phần NABIT Việt Nam</span>
              <span><small>Nền tảng quản trị xăng dầu toàn diện</small></span>
          </div>
      </div>
  </div>
  <!--a class="banner__btn-2" href="#!">Tư vấn online</a-->
</section>
<!-- End Banner-->
