import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html'
})
export class VisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log("Vision");
  }

}
