import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html'
})
export class ProductsComponent implements OnInit {
  constructor() { }
  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 4000,
    smartSpeed: 800,
    margin: 40,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 3
      },
      1200: {
        items: 4,
        nav:false
      }
    }
  }
  ngOnInit(): void {
    console.log("products init!");
  };
}
