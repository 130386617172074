<!-- banner-->
<section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #f5f5f5; padding: 10px;">
    <div class="container">
      <div class="row magin-full shadow-5" style="background-color: white">
        <div class="col-md-3 col-sm-12 col-xs-12 py-5 bhoechie-tab-menu bg-blue-lighter">
          <app-about-navleft></app-about-navleft>
        </div>
        <div class="col-md-9 col-sm-12 col-xs-12 p-3 text-justify" style="font-size:16px">
          <!--img class='img-responsive' src='./assets/images/Mission_Vision_OurValue.jpg' /-->
          <div class='row px-3'>
            <div class='col-md-6 p-5 shadow-10' style='background-color: rgb(113, 8, 2);color:white;'>
                <h1 style="color:white">Tầm nhìn</h1>
                <p>Bằng nỗ lực sáng tạo trong khoa học, công nghệ và đổi mới trong quản trị, NABIT mong muốn trở thành công ty có nền tảng, phần mềm và dịch vụ được sử dụng phổ biến cho ngành xăng dầu trong nước và quốc tế.</p>
            </div>
            <div class='col-md-6 p-0'>
              <img class='img-responsive' src='./assets/images/AboutUs/Mission.jpg'>
            </div>
          </div>
          <div class='row px-3'>
            <div class='col-md-6 p-0'>
              <img class='img-responsive' src='./assets/images/AboutUs/Vision.jpg'>
            </div>
            <div class='col-md-6 p-5 shadow-10' style='background-color: rgb(255, 107, 1);color:white;'>
              <h1 style="color:white">Sứ mệnh</h1>
              <p>Sứ mệnh của NABIT là phát triển các nền tảng, phần mềm và dịch vụ công nghệ thông tin để thay
                đổi ngành kinh doanh xăng dầu, giúp khách hàng thực hiện công việc theo phương thức mới,
                năng
                suất và hiệu quả hơn nhằm thúc đẩy sự phát triển của đất nước và các quốc gia trên thế
                giới.</p>
            </div>
          </div>
          <div class='row px-3'>
            <div class='col-md-6 p-5 shadow-10' style='background-color: rgb(42, 148, 164);color:white;'>
              <h1 style="color:white">Giá trị cốt lõi</h1>
              <p> - Tin cậy: Các nền tảng, sản phẩm và dịch vụ mà NABIT mang lại cho khách hàng đều có độ tin cậy cao. Con
                người NABIT với tri thức và văn hóa cao luôn mang lại cho khách hàng cảm giác tin cậy trong giao dịch và
                chuyển giao tri thức, công nghệ.</p>
              <p> - Tiện ích: Các nền tảng, sản phẩm và dịch vụ NABIT luôn thỏa mãn mọi yêu cầu nghiệp vụ của khách hàng.
                Khách hàng có thể dễ dàng tiếp cận và sử dụng nền tảng, sản phẩm, dịch vụ của NABIT bất cứ khi nào, bất
                cứ nơi nào. Đội ngũ tư vấn, hỗ trợ khách hàng NABIT luôn sẵn sàng phục vụ 365 ngày/năm và 24 giờ/ngày.
              </p>
              <p> - Tận tình: Con người NABIT từ những người phát triển nền tảng, sản phẩm đến những người kinh doanh tư
                vấn và các bộ phận khác luôn luôn tận tâm, tận lực phục vụ vì lợi ích của khách hàng, làm cho khách hàng
                tin cậy và yêu mến như một người bạn, một người đồng hành trong sự nghiệp.
              </p>
            </div>
            <div class='col-md-6 col-sm-12 p-0'>
              <img class='img-responsive' src='./assets/images/AboutUs/Value.jpg'>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>