import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  div1:boolean = false;
  ProductClick(){
    console.log('click');
    this.div1 = !this.div1;
  }
}
