<section class="section over-hidden pb-5">
  <div class="container">
      <div class="row">
            <div class="col-md-6 col-sm-6 pt-4">
                <h3 class="heading text-cyan bold-2">Nền tảng Cloud-Base & Kiến trúc Microservices</h3>
                <p class="text-justify">Giải pháp phần mềm xây dựng trên nền tảng Cloud Base, người dùng dễ dàng truy cập hệ thống mọi nơi, mọi lúc, mọi thiết bị. Thiết kế hệ thống theo kiến trúc Microservices, linh hoạt đáp ứng các yêu cầu của khách hàng với tốc độ tối đa có thể.</p>
            </div>
            <div class='col-md-6 col-sm-6 pt-4 text-center'>
                <!--img class='img-responsive' src="./assets/images/Microservice1.png" /-->
                <img class='img-responsive' src="./assets/images/MicroService2_1.png" style='width:100%;max-width:400px' />
            </div>
      </div>
      <div class="row pt-2">
            <div class='col-md-6 col-sm-6 d-none d-sm-block text-center'>
                <img class='img-responsive' src="./assets/images/integration-diagram1.png" style='margin-top:-3vw;width:100%;max-width:360px'/>
            </div>
            <div class="col-md-6 col-sm-6 pb-3">
                <h3 class="heading text-cyan bold-2">Tích hợp hệ thống</h3>
                <p class="text-justify">Hệ thống của NABIT được thiết kế mở, dễ dàng tích hợp với các ứng dụng khác, tạo ra một trải nghiệm xuyên suốt. Người dùng chỉ thao tác trên 01 hệ thống, dữ liệu được tự động luân chuyển giữa các hệ thống với nhau theo cấu trúc chuẩn mực.</p>
            </div>
            <div class='col-md-6 col-sm-6 d-sm-none d-block text-center'>
                <img class='img-responsive' src="./assets/images/integration-diagram1.png" style="width:100%;max-width:360px"/>
            </div>
      </div>
  </div>
</section>