<!-- banner-->
<!--section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
</section-->
<!-- banner-->
<section class="banner py-3 mt-5" style='background-color: #f5f5f5;'>
  <div class="container">
    <div class='row'>
      <div class="col-sm-6 col-xs-12 banner__content">
        <span style='background-color: transparent'><b>Giải pháp quản lý</b><br>
          <font class='text-orange'> cửa hàng xăng dầu</font>
        </span>
        <font class='text-justify'>
          Giải pháp quản lý cửa hàng xăng dầu là hệ thống phần mềm đáp ứng nghiệp vụ quản lý cửa hàng xăng dầu, bao gồm phân hệ quản lý tại các CHXD và phân hệ quản lý số liệu tổng hợp từ các CHXD tại Văn phòng Công ty. Hệ thống đã được thiết kế để tích hợp với hệ thống Tự đồng hóa CHXD, hệ thống phát hành hóa đơn điện tử và hệ thống Công thông tin doanh nghiệp.
        </font>
      </div>
      <div class='col-sm-6 col-xs-12 pt-3'>
        <img class="banner__img" src="./assets/images/Products/QLCH.png" style="float:right" alt="">
      </div>
    </div>
  </div>
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #fff; padding: 10px;">
    <div class="container">
      <div class="row magin-full" style="background-color: white">
        <div class="col-md-12 col-sm-12 col-xs-12 py-5 text-justify" style="font-size:18px">
          <div style='float:left;padding:10px;text-align: center; width:50%;'>
            <!--img src='./assets/images/Products/ERP2.png' style='max-width: 100%;' /><br /-->
            <img src='./assets/images/Products/QLCH1.jpg' style='max-width: 100%;' /><br />
          </div>
          <h3 style="color:#1a5493">
            <small><i class='fa fa-diamond' style='color:orange;font-weight: bold'></i></small>
            &nbsp;Các chức năng chính của hệ thống
          </h3>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;&nbsp;Quản lý ca bán hàng và hàng hóa tại cửa hàng
          </font>
          <ul>
            <li>Quản lý Nhập/Xuất hàng hóa (Trường hợp nhập xuất chuyển thẳng: quản lý các hình thức bán hàng không qua cột bơm, hình thức này được thống kê riêng để tách trong việc tính toán, theo dõi hao hụt định mức sau này).
              thiểu rủi ro về tài chính.</li>
            <li>Quản lý Tồn kho hàng hóa: Tồn kho sổ sách và tồn kho thực tế, cảnh báo về tồn kho tối thiểu tại cửa hàng.</li>
            <li>Quản lý hao hụt định mức: Được tự động tính và hỗ trợ tạo chứng từ xuất kho theo tỷ lệ hao hụt định mức cửa hàng được hưởng, giám sát hao hụt, rò rỉ, mất hàng</li>
            <li>Kiểm kê: Tự động xác định lượng chênh lệch giữa tồn kho sổ sách và tồn kho thực tế tại thời điểm kiểm kê, hỗ trợ tạo chứng từ nhập thừa xuất thiếu cho người sử dụng.</li>
            <li>Quản lý bể: Quản lý thông tin thẻ bể, báo cáo tồn kho trống bể.</li>
            <li>Sẵn sàng cho việc tích hợp với các hệ thống Tự động hóa: cột bơm, đo bể, POS.</li>
          </ul>
          <div style='float:right;padding:10px;text-align: center; width:50%;'>
            <img src='./assets/images/Products/QLCH3.webp' style='max-width: 100%;' />
          </div>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;Quản lý tiền và các khoản tương đương Tiền tại Cửa hàng:
          </font>
          <ul>
            <li>Khách hàng trả trực tiếp cho ca bán hàng, hoặc theo dõi chi tiết theo từng nhân viên bán hàng trong ca.</li>
            <li>Khách hàng trả tiền cho cửa hàng, cửa hàng báo có cho ca để bán hàng.</li>
            <li>Khách hàng trả tiền cho công ty/hoặc qua ngân hàng, công ty báo có cho cửa hàng để bán hàng.</li>
            <li>Tiền thu được của cửa hàng nộp trực tiếp cho công ty; hoặc nộp qua tài khoản chuyên thu tại Ngân hàng.</li>
            <li>Các khoản báo có của Công ty cho Cửa hàng (tại công ty sẽ phải tách chi tiết khách hàng trả cho cửa hàng nào).</li>
            <li>Quản lý công nợ khách hàng, cửa hàng.</li>
          </ul>
          <div style='float:left;padding:10px;text-align: center; width:50%;'>
            <img src='./assets/images/Products/Mo hinh 5-2.jpg' style='max-width: 100%;' />
          </div>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;Tích hợp hệ thống. Sẵn sàng tích hợp với các hệ thống Tự động hóa và các hệ thống khác, bao gồm:
          </font>
          <ul>
            <li>Tự động hóa đo bể</li>
            <li>Tự động hóa kết nối tín hiệu cột bơm</li>
            <li>Hệ thống phát hành Hóa đơn điện tử</li>
            <li>Hệ thống Cổng thông tin doanh nghiệp</li>
            <li>Hệ thống Kế toán hiện có tại đơn vị</li>
          </ul>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;Quản lý tại Trung tâm: Dữ liệu cập nhật vào phần mềm QLCH tại các CHXD, thông qua hệ thống đồng bộ dữ liệu tự động sẽ được đồng bộ về hệ thống QLCH trung tâm. Tại đây hệ thống QLCH trung tâm có thể tổng hợp dữ liệu của các cửa hàng thông qua hệ thống báo cáo/bảng kê mà không cần đợi cửa hàng gửi báo cáo. Thông tin bao gồm
          </font>
          <ul>
            <li>Nhập - xuất - tồn kho sổ sách/vật lý từng CH/toàn hệ thốngli/p>
            <li>Tiền mặt/Công nợ tại CHXD/toàn hệ thống</li>
            <li>Hàng hóa/khách hàng tại từng cửa hàng/toàn hệ thống</li>
            <li>Thay đổi kiểm soát giá đến từng cửa hàng/toàn hệ thống</li>
            <li>Quản lý từ điển/dữ liệu chia sẻ chung toàn hệ thống</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
