<!-- banner-->
<section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
  <!--a class="banner__btn-2" href="#!">Tư vấn online</a-->
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #f5f5f5; padding: 10px;">
    <div class="container">
      <div class="row magin-full shadow-5" style="background-color: white">
        <div class="col-md-3 col-sm-3 col-xs-3 py-5 bhoechie-tab-menu bg-blue-lighter">
          <app-about-navleft></app-about-navleft>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-9 p-5 text-justify" style="font-size:16px">
          <h1 style="color:#1a5493">Chính sách hoạt động</h1>
          <img src="./assets/images/Chinh sach hoat dong.webp" style='max-width:90%' />
        </div>
      </div>
    </div>
  </div>
</section>
