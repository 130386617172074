<div class="msp-item-card-container">
  <div class="list-item-container custom-scroll" num="3" style="max-width:1500px">
    <!--START-->
    <div class="msp-item-card-wrap recommend module ItemCardModule shadow-5 mr-1 bc-orange" module="ItemCardModule"
      style="border-bottom-color: rgb(59, 80, 223);" style='width:100%;max-width:1200px'>
      <div style="height: 32px; width: 100%" objname="jSuggestItem" class="show">
        <div class="icon-star-white package-suggest bg-orange">
            Gói khuyên dùng
        </div>
      </div>
      <div class="msp-item-card">
        <div class="msp-item-name">Pro</div>
        <div class="under-line bg-orange"></div>
        <div class="msp-btn-buy bg-orange" routerLink="/ContactUs">Liên hệ</div>
        <div class="msp-item-detail">
          <div class="msp-view-detail">
            <div class="item-detail">
              <div class="item-text text-orange"><b>Phân hệ Mua hàng (MM - Material Management)</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình đặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình nhập khẩu</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình giao nhận hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý hóa đơn, thanh toán, ngoại tệ</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý các unit costs của hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Các quy trình quản lý về lượng và giá trị hàng hóa tại kho</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Hệ thống báo cáo mua hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Phân hệ Bán hàng (SD - Sale Distribution)</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý giá bán</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý đơn đặt hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình giao nhận hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quy trình bán hàng gửi</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý liên kết với phân hệ MM</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý hóa đơn, hạn mức công nợ</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Hệ thống báo cáo bán hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Phân hệ Quản lý kho (WM - Warehouse Management)</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Quản lý nhập - xuất - tồn hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kiểm kê hàng hóa đột xuất, theo kỳ</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Trị giá hàng tồn kho theo các phương pháp: FIFO/Moving Average/Standard đã được thiết lập cho từng Material</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Hệ thống báo cáo về tồn kho, nhà cung cấp, đơn hàng, danh sách báo giá, báo cáo hàng đang đi đường ...</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp hệ thống Tự động hóa đo bồn tự động</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Tích hợp tự động hóa bến xuất</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Phân hệ Kế toán tài chính (FI - Financial)</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Công nợ phải trả</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Công nợ phải thu</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán tiền mặt</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán ngân hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán ngoại tệ.</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán tài sản cố định.</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Hệ thống báo cáo kế toán tài chính theo chuẩn mực nhà nước.</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Phân hệ Kế toán quản trị (CO - Controlling)</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán trung tâm chi phí (Cost Center)</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán trung tâm lợi nhuận (Profit Center)</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán quản trị và phân bổ chi phí chung</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Kế toán các khoản chi phí và doanh thu nội bộ giữa các đơn vị</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-orange"></div>
              <div class="item-text">Hệ thống báo cáo quản trị.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END-->
  </div>
</div>
