<!-- footer-->
<footer class="footer">
  <div class="container">
      <div class="footer__section">
          <h2 class="footer__heading">
            <img src="./assets/images/NABIT_Logo.png" style='width:60px;padding-bottom: 10px;' alt="" /><br>
            <b>CÔNG TY CỔ PHẦN NABIT VIỆT NAM</b></h2>
          <div class="footer__address">
              <span>Số 89 ngõ 64 Đường Kim Giang, Phường Kim Giang, Quận Thanh Xuân, Hà Nội</span>
              <br/>
              <span>Email: infor@nabit.com.vn</span>
              <br/>
              <span>Website: www.nabit.vn</span>
          </div>
      </div>
      <ul class="d-flex justify-content-center list-unstyled mb-0">
          <li class="px-2">
              <a class="social-link" href="#!">
                  <i class="fa fa-facebook"></i>
              </a>
          </li>
          <li class="px-2">
              <a class="social-link" href="#!">
                  <i class="fa fa-youtube-play"></i>
              </a>
          </li>
          <li class="px-2">
              <a class="social-link" href="#!">
                  <i class="fa fa-google-plus"></i>
              </a>
              <a class="social-link" href="#!">
                  <i class="fa fa-twitter"></i>
              </a>
          </li>
      </ul>
      <div class="copyright">Copyright @ 2020 NABIT VIETNAM JSC, all right reserved</div>
  </div>
</footer>
<!-- end footer-->
