<!-- banner-->
<!--section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
</section-->
<!-- banner-->
<section class="banner py-3 mt-5" style='background-color: #f5f5f5;'>
  <div class="container">
    <div class='row'>
      <div class="col-sm-6 col-xs-12 banner__content">
        <span style='background-color: transparent;font-weight: bold;'><b>Giải pháp quản lý<br>bán buôn xăng dầu</b>
        </span><font class='text-justify'>Giải pháp này là một phần nhỏ của giải pháp ERP cho ngành xăng dầu, áp dụng cho các doanh nghiệp kinh doanh xăng dầu không có chức năng nhập khẩu xăng dầu mà chỉ nhập hàng từ các đầu mối xăng dầu. </font>
      </div>
      <div class='col-sm-6 col-xs-12'>
        <img class="banner__img" src="./assets/images/Products/KDXD.png" style="float:right" alt="">
      </div>
    </div>
  </div>
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #fff; padding: 10px;">
    <div class="container">
      <div class="row magin-full" style="background-color: white">
        <div class="col-md-12 col-sm-12 col-xs-12 py-5 text-justify" style="font-size:18px">
          <div class='mr-5' style='float:left;text-align: center; width:50%;'>
            <!--img src='./assets/images/Products/ERP2.png' style='max-width: 100%;' /><br /-->
            <img src='./assets/images/Products/KDXD2.jpg' style='max-width: 100%;' /><br />
          </div>
          <h3 style="color:#1a5493">
            <small><i class='fa fa-diamond' style='color:orange;font-weight: bold'></i></small>
            &nbsp;Lợi ích khi sử dụng hệ thống
          </h3>
          <p>&nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>&nbsp;&nbsp;Giải pháp đặc thù cho đơn vị kinh doanh xăng dầu không phải là đầu mối xăng dầu, đáp ứng đầy đủ ccs nghiệp vụ của đơn vị. </p>
          <p>&nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>&nbsp;&nbsp;Chức năng đơn giản, dễ sử dụng bám sát theo quy trình quản lý của đơn vị, giúp tiết kiệm được chi phí triển khai cũng như thời gian triển khai ứng dụng</p>
          <p>&nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>&nbsp;&nbsp;Giúp nâng cao năng lực quản lý và kiểm soát nguonf lực của Công ty, tăng tính minh bạch, chủ động trong quản lý hàng hóa và kế hoạch hóa dòng tiền và giảm thiểu rủi ro về tài chính</p>
          <p>&nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>&nbsp;&nbsp;Giúp quản trị được các kênh bán hàng, từng khách hàng ... Từ đó xây dựng và quyết định chính sách giá và chiết khấu cho từng kênh bán hàng, từng khách hàng theo vùng, miền, đơn vị một cách triệt để và chính xác.</p>
          <p>&nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>&nbsp;&nbsp;Kết nối với các hệ thống: Cổng thông tin doanh nghiệp, hệ thống phát hành hóa đơn diện tử, hệ thống phát hành phiếu xuất kho điện tử.</p>
          <div class='ml-5' style='float:right;text-align: center; width:50%;'>
            <img src='./assets/images/Products/KDXD1.jpg' style='max-width: 100%;' />
          </div>
          <h3 style="color:#1a5493">
            <small><i class='fa fa-diamond' style='color:orange;font-weight: bold'></i></small>
            &nbsp;Các chức năng của hệ thống
          </h3>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;&nbsp;Quản lý nhập hàng và các nghiệp vụ quản lý nguồn hàng
          </font>
          <ul>
            <li>Nhập phân nguồn tử TCTY</li>
            <li>Nhập hàng từ các đơn vị kinh doanh, nhập hàng vay</li>
            <li>Nhập hàng từ nguồn ngoài</li>
            <li>Nhập chuyển nguồn, nhập thừa kiểm kê, nhập khác</li>
            <li>Các báo cáo về nhập hàng và nguồn hàng.</li>
          </ul>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;Quản lý các nghiệp vụ xuất hàng và công nợ
          </font>
          <ul>
            <li>Khách hàng trả trực tiếp cho ca bán hàng, hoặc theo dõi chi tiết theo từng nhân viên bán hàng trong ca.</li>
            <li>Khách hàng trả tiền cho cửa hàng, cửa hàng báo có cho ca để bán hàng.</li>
            <li>Khách hàng trả tiền cho công ty/hoặc qua ngân hàng, công ty báo có cho cửa hàng để bán hàng.</li>
            <li>Tiền thu được của cửa hàng nộp trực tiếp cho công ty; hoặc nộp qua tài khoản chuyên thu tại Ngân hàng.</li>
            <li>Các khoản báo có của Công ty cho Cửa hàng (tại công ty sẽ phải tách chi tiết khách hàng trả cho cửa hàng nào).</li>
            <li>Quản lý công nợ khách hàng, cửa hàng.</li>
          </ul>
          <div class='mr-5' style='float:left;text-align: center; width:50%;'>
            <img src='./assets/images/Products/new-banner.png' style='max-width: 100%;' />
          </div>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;Tích hợp hệ thống. Sẵn sàng tích hợp với các hệ thống Tự động hóa và các hệ thống khác, bao gồm:
          </font>
          <ul>
            <li>Tự động hóa đo bể</li>
            <li>Tự động hóa kết nối tín hiệu cột bơm</li>
            <li>Hệ thống phát hành Hóa đơn điện tử</li>
            <li>Hệ thống Cổng thông tin doanh nghiệp</li>
            <li>Hệ thống Kế toán hiện có tại đơn vị</li>
          </ul>
          <font style='color:#1a5493;font-weight: bold;'>
            &nbsp;&nbsp;<i class='fa fa-check' style='color:orange'></i>
            &nbsp;Quản lý tại Trung tâm: Dữ liệu cập nhật vào phần mềm QLCH tại các CHXD, thông qua hệ thống đồng bộ dữ liệu tự động sẽ được đồng bộ về hệ thống QLCH trung tâm. Tại đây hệ thống QLCH trung tâm có thể tổng hợp dữ liệu của các cửa hàng thông qua hệ thống báo cáo/bảng kê mà không cần đợi cửa hàng gửi báo cáo. Thông tin bao gồm
          </font>
          <ul>
            <li>Nhập - xuất - tồn kho sổ sách/vật lý từng CH/toàn hệ thốngli/p>
            <li>Tiền mặt/Công nợ tại CHXD/toàn hệ thống</li>
            <li>Hàng hóa/khách hàng tại từng cửa hàng/toàn hệ thống</li>
            <li>Thay đổi kiểm soát giá đến từng cửa hàng/toàn hệ thống</li>
            <li>Quản lý từ điển/dữ liệu chia sẻ chung toàn hệ thống</li>
          </ul>
          <img src='./assets/images/Products/123.jpg' style='max-width: 100%;' />
        </div>
      </div>
    </div>
  </div>
</section>
