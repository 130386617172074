<!-- banner-->
<section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
  <!--a class="banner__btn-2" href="#!">Tư vấn online</a-->
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #f5f5f5; padding: 10px;">
    <div class="container">
      <div class="row magin-full shadow-5" style="background-color: white">
        <div class="col-md-3 col-sm-3 col-xs-3 py-5 bhoechie-tab-menu bg-blue-lighter">
          <app-about-navleft></app-about-navleft>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-9 p-5 text-justify" style="font-size:16px">
          <h1 style="color:#1a5493">Triết lý kinh doanh</h1>
          <div class='row'>
            <div class='col-sm-8'>
              <h2 style="color:rgb(255, 81, 0)">Với khách hàng</h2>
              <ul>
                <li>Chất lượng, uy tín, tiến độ</li>
                <li>Luôn thỏa mãn tất cả các nhu cầu của khách hàng với chất lượng tốt nhất</li>
                <li>Cởi mở, thân thiện, cầu thị, nhiệt tình và trân trọng</li>
                <li>Nỗ lực cao nhất để các sản phẩm và dịch vụ luôn tiến bộ hơn, góp phần nâng cao hiệu quả hoạt động của khách hàng.</li>
              </ul>
            </div>
            <div class='col-sm-4'>
              <img src='./assets/images/ForCustomer.webp' style='width:90%' />
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-4'>
              <img src='./assets/images/ForEmployee.webp' style='width:90%' />
            </div>
            <div class='col-sm-8'>
              <h2 style="color:rgb(255, 81, 0)">Với nhân viên</h2>
              <ul>
                <li>Tạo cơ hội học tập, nâng cao trình độ và tác phong làm việc, từng bước đạt tiêu chuẩn quốc tế.</li>
                <li>Tạo cơ hội thăng tiến trên cơ sở hiệu quả công việc, tính chính trực và lòng trung thành với công ty, nhằm đảm bảo ngày càng tốt hơn cho cuộc sống của nhân viên và sự phát triển bền vững của công ty.</li>
              </ul>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-8'>
              <h2 style="color:rgb(255, 81, 0)">Với đối tác</h2>
              <ul>
                <li>Xây dựng và gìn giữ mối quan hệ đoàn kết, ổn định, lâu dài và cùng có lợi, trên cơ sở truyền thống kinh doanh đáng tin cậy, đảm bảo chất lượng hàng hóa dịch vụ và tôn trọng đối tác.</li>
                <li>Hỗ trợ để cùng nâng cao chất lượng kinh doanh nhằm đảm bảo tốt hơn nhu cầu khách hàng</li>
              </ul>
            </div>
            <div class='col-sm-4'>
              <img src='./assets/images/ForPartner.webp' style='width:90%' />
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-4'>
              <img src='./assets/images/ForComunity.webp' style='width:90%' />
            </div>
            <div class='col-sm-8'>
              <h2 style="color:rgb(255, 81, 0)">Với cộng đồng</h2>
              <ul>
                <li>Tạo cơ hội học tập, nâng cao trình độ và tác phong làm việc, từng bước đạt tiêu chuẩn quốc tế.</li>
                <li>Tạo cơ hội thăng tiến trên cơ sở hiệu quả công việc, tính chính trực và lòng trung thành với công ty, nhằm đảm bảo ngày càng tốt hơn cho cuộc sống của nhân viên và sự phát triển bền vững của công ty.</li>
              </ul>
            </div>
          </div>
          <div class='row'>
            <div class='col-sm-12 text-center'><h2>"UY TÍN - CHẤT LƯỢNG - NHANH CHÓNG<br>GIÁ CẢ HỢP LÝ"</h2></div>
            <div class='col-sm-12 color-blue'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lấy khách hàng làm trọng tâm, Công ty Cổ phần NABIT Việt Nam cam kết phấn đấu nhằm thỏa mãn tối đa nhu cầu khách hàng. Chúng tôi lấy tín nhiệm của khách hàng làm mục đích hoạt động. Chúng tôi cam kết bàn giao sản phẩm dịch vụ đảm bảo chất lượng, số lượng và thời gian.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
