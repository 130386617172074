<!-- standard-->
<section class="section pb-5 bg-gray">
  <div class="container">
    <div class="section__header">
      <h2 class="section__heading">Hệ quản trị kinh doanh xăng dầu 4.0</h2>
      <div class="section__desc">Tích hợp tất cả các ứng dụng quản lý doanh nghiệp kinh doanh xăng dầu bạn đang cần trên
        cùng 1 nên tảng duy nhất</div>
    </div>
    <owl-carousel-o [options]="customOptions" class='slider-respond'>
      <ng-template carouselSlide>
        <a href="/Product/ERP">
          <div class="h-100 mb-1">
            <article class="standard">
              <span class="img-wrap img-zoom d-block">
                <img src="./assets/images/Products/ERP4.png" class="img-thumbnail" alt="" />
              </span>
              <div class="standard__body">
                <h3 class="standard__title">
                  <span class="d-block"><a href="/Product/ERP">Giải pháp ERP xăng dầu</a></span>
                </h3>
                <div class="text-justify limit-line limit-line--2">Hệ thống quản trị nguồn lực doanh nghiệp tổng thể cho
                  các đơn vị kinh doanh xăng dầu</div>
              </div>
            </article>
          </div>
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="/Product/QLCH">
          <div class="h-100">
            <article class="standard">
              <span class="img-wrap img-zoom d-block" href="/Product/QLCH">
                <img src="./assets/images/Products/QLCH3.webp" class='img-thumbnail' alt="" />
              </span>
              <div class="standard__body">
                <h3 class="standard__title">
                  <span class="d-block"><a href="/Product/QLCH">Quản lý CHXD</a></span>
                </h3>
                <div class="text-justify limit-line limit-line--2">Hệ thống phần mềm đáp ứng nghiệp vụ quản
                  lý cửa hàng xăng dầu
                </div>
              </div>
            </article>
          </div>
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="/Product/KDXD">
          <div class="h-100">
            <article class="standard">
              <a class="img-wrap img-zoom d-block" href="/Product/KDXD">
                <img src="./assets/images/Products/KDXD.png" class='img-thumbnail' alt="" />
              </a>
              <div class="standard__body">
                <h3 class="standard__title">
                  <span class="d-block"><a href="/Product/KDXD">Quản lý KDXD</a></span>
                </h3>
                <div class="text-justify limit-line limit-line--2">Giải pháp quản lý kinh doanh xăng dầu tại Văn phòng Công ty
                </div>
              </div>
            </article>
          </div>
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="/Product/QLKho">
          <div class="h-100">
            <article class="standard">
              <span class="img-wrap img-zoom d-block">
                <img src="./assets/images/Products/Kho2.png" class="img-thumbnail" alt="" />
            </span>
              <div class="standard__body">
                <h3 class="standard__title">
                  <span class="d-block"><a href="/Product/QLKho">Quản lý kho</a></span>
                </h3>
                <div class="text-justify limit-line limit-line--2">Giải pháp quản lý các kho xăng dầu trực thuộc Công ty, liên kết với các phần mềm khác</div>
              </div>
            </article>
          </div>
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="/Product/QLCH">
          <div class="h-100">
            <article class="standard">
              <span class="img-wrap img-zoom d-block" href="/Product/QLCH">
                <img src="./assets/images/Products/automatic fuel system.webp" class="img-thumbnail" alt="" />
              </span>
              <div class="standard__body">
                <h3 class="standard__title">
                  <span class="d-block"><a href="/Product/QLCH">Tự động hóa CHXD</a></span>
                </h3>
                <div class="text-justify limit-line limit-line--2">Tích hợp tự động hóa tại CHXD, bao gồm TĐH đo bể, TĐH cột bơm</div>
              </div>
            </article>
          </div>
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="/Product/QLKho">
          <div class="h-100">
            <article class="standard">
              <span class="img-wrap img-zoom d-block">
                <img src="./assets/images/Products/WHAutomatic.jpg" class="img-thumbnail" alt="" />
              </span>
              <div class="standard__body">
                <h3 class="standard__title">
                  <span class="d-block"><a href="/Product/QLKho">Tự động hóa kho</a></span>
                </h3>
                <div class="text-justify limit-line limit-line--2">Tích hợp tự động hóa tại kho, bao gồm TĐH đo bồn, TĐH bến xuất...</div>
              </div>
            </article>
          </div>
        </a>
      </ng-template>
      <div class="owl-nav">
        <button type="button" role="presentation" class="owl-prev"><i class="fa fa-caret-left"></i></button>
        <button type="button" role="presentation" class="owl-next"><i class="fa fa-caret-right"></i></button>
      </div>
    </owl-carousel-o>
  </div>
</section>
