<!-- banner-->
<section class="banner">
  <img class="banner__img" src="./assets/images/Banner/Banner3.jpg" style="width:100%" alt="">
  <!--a class="banner__btn-2" href="#!">Tư vấn online</a-->
</section>
<!-- End Banner-->
<section id="new">
  <div class="wrapper not-index" style="background-color: #f5f5f5; padding: 10px;">
    <div class="container">
      <div class="row magin-full shadow-5" style="background-color: white">
        <div class="col-md-3 col-sm-3 col-xs-3 py-5 bhoechie-tab-menu bg-blue-lighter">
          <app-about-navleft></app-about-navleft>
        </div>
        <div class="col-md-9 col-sm-9 col-xs-9 p-3 text-justify" style="font-size:16px">
          <img class='img-responsive' src='./assets/images/AboutUs/ThuNgo.png' />
          <div class="p-5 text-justify" style="margin-top:-75px">
            <div class='text-center' style='font-size:18px'>
              <h2 style="color:#1a5493">Kính gửi Quý khách hàng</h2>
            </div>
            <p>Trước hết xin gửi tới quí vị lời chào trân trọng và lời chúc sức khỏe.</p>
            <p>Chúc doanh nghiệp của quí vị ngày càng hưng thịnh.</p>
            <p>Không cần giới thiệu hẳn quí vị cũng biết rõ:</p>
            <p>-  Phần mềm tin học – sản phẩm công nghệ cao trong thời đại hiện nay – có ý nghĩa thiết yếu trong việc hiện đại hóa công tác quản trị doanh nghiệp một cách toàn diện, góp phần giúp cho doanh nghiệp nâng cao sức cạnh tranh và phát triển bền vững.</p>
            <p>-  Sử dụng một phần mềm tốt sẽ giúp loại bỏ hầu hết những hạn chế, vướng mắc không đáng có của công tác kế toán, cập nhật tức thời thông tin tài chính, quản trị; giúp người quản lý nắm bắt sát sao họat động của doanh nghiệp, làm cơ sở cho việc ra quyết định kinh doanh đúng đắn, kịp thời.</p>
            <p>-  Sẽ là bất cập nếu doanh nghiệp của quí vị chưa sử dụng phần mềm,</p>
            <p>-  Nhưng, sẽ bất cập không kém, nếu doanh nghiệp của quí vị đã hoặc đang sử dụng một phần mềm mà vẫn không đáp ứng được yêu cầu công việc hay hiệu quả công việc không cao. Thực tế nhiều doanh nghiệp đã phải trả giá cho sự lựa chọn không chính xác khi không tìm hiểu về tính năng và sự ổn định của phần mềm, dẫn đến phần mềm không đáp ứng được những đặc thù về ngành nghề của doánh nghiệp; thậm chí còn thiệt hại về kinh tế do mất chi phí đầu tư lại phần mềm khác, chi phí đào tạo nhân viên và chi phí cơ hội cho thời gian đã mất.</p>
            <p>-  Bởi vậy, quí vị cần thận trọng khi tìm kiếm và quyết định lựa chọn một phần mềm sao cho hữu ích, tiện dụng, đáp ứng tốt yêu cầu quản trị của doanh nghiệp.</p>
            <p>Để làm điều đó quí vị nên tham khảo và kiểm tra thực tế tính hữu ích của phần mềm.</p>
            <p>Với đội ngũ chuyên gia có kinh nghiệm hơn 15 năm phát triển phần mềm doanh nghiệp, nhất là trong lĩnh vực kinh doanh xăng dầu, chúng tôi cung cấp một giải pháp toàn diện cho doanh nghiệp kinh doanh xăng dầu, giải quyết hiệu quả nhiều tình huống đặc thù của riêng ngành xăng dầu.</p>
            <p>Chúng tôi có nhiều lý do để tự tin chào đón quí vị và các bạn đến với các sản phẩm của Công ty Cổ phần NABIT Việt Nam</p>
            <p><img class='img-responsive' src='./assets/images/AboutUs/MrHuy_Sign.png' style="float:right;height:150px;margin-bottom:30px" /></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
