<div class="msp-item-card-container">
  <div class="list-item-container custom-scroll" num="3" style="max-width:1500px">
    <!--START-->
    <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1" module="ItemCardModule" style="border-bottom-color: rgb(59, 80, 223);">
      <div class="msp-item-card">
        <div class="msp-item-name">Basic</div>
        <div class="under-line bg-blue"></div>
        <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Sử dụng cho Cửa hàng đơn lẻ, không có nghiệp vụ quản lý nhiều CHXD</small></div>
        <div class="msp-btn-buy bg-blue">Liên hệ</div>
        <div class="msp-item-detail">
          <div class="msp-view-detail">
            <div class="item-detail">
              <div class="item-text"><b>Quản lý danh mục</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Danh mục cấu hình CHXD</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Danh mục của cửa hàng: Khách hàng, kho hàng, hợp đồng, hồ sơ giá …</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý hàng hóa tại CHXD</b> </div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý nhập hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý bán hàng theo ca</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý tồn kho hàng hóa: Tồn sổ sách và tồn thực tế</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Cảnh báo về tồn kho tối thiểu</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý hao hụt: Theo sổ sách và thực tế</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Giám sát hao hụt, rò rỉ, mất hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tiền hàng & Công nợ</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý tiền mặt tại cửa hàng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý tiền chuyển khoản/báo nợ, có</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý công nợ khách hàng, cửa hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tính tuổi nợ, công nợ vượt quá hạn mức</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Hóa đơn, ấn chỉ</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Quản lý lượng hóa đơn đã xuất theo từng khách hàng, theo từng thời điểm thay đổi giá</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Báo cáo xuất bán theo hóa đơn, công nợ theo hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp hóa đơn điện tử: Cảnh báo khi hết dải hóa đơn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Hệ thống báo cáo, sổ sách</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Màn hình Dashboard của Cửa hàng trưởng</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Hệ thống báo cáo chi tiết cho CHXD</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Hệ thống báo cáo tổng hợp</b></div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Màn hình Dashboard cho cán bộ quản lý</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Tổng hợp số liệu nhập hàng/xuất bán toàn Công ty</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Tổng hợp số liệu tồn kho/bể toàn Công ty</div>
            </div>
            <div class="item-detail">
              <div class="icon-item bc-blue"></div>
              <div class="item-text">Tổng hợp số liệu Tiền hàng/công nợ toàn Công ty</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Hệ thống báo cáo quản trị</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo ước doanh thu/sản lượng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo hiệu quả kinh doanh toàn công ty/theo CHXD</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Báo cáo lãi lỗ/giá vốn toàn công ty/theo CHXD</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Quản lý bể & cột bơm</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Hồ sơ bể, lịch sử sửa chữa/bảo dưỡng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Hồ sơ cột bơm, lịch sử sửa chữa/bảo dưỡng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Hồ sơ pháp lý, cảnh báo hết hạn</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tính năng khác</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quản lý kiểm kê (Thay đổi giá/tháng/quý/năm)</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quản lý chi phí CHXD (chi phí thực tế, chi phí khoán)</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quy trình phê duyệt/điều động hàng hóa Công ty => Cửa hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Quy trình phê duyệt mở ca</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Cảnh báo tồn kho, trống bể hàng hóa</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Cảnh báo rủi ro công nợ khách hàng</div>
            </div>
            <div class="item-detail">
              <div class="item-text"><b>Tích hợp hệ thống</b></div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp hóa đơn điện tử</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp tự động hóa cột bơm</div>
            </div>
            <div class="item-detail">
              <div class="item-text-delete">Tích hợp tự động hóa đo bể</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--END-->
        <!--START-->
        <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1 bc-orange" module="ItemCardModule">
          <div style="height: 32px; width: 100%" objname="jSuggestItem" class="show">
            <div class="icon-star-white package-suggest bg-orange">
                Gói khuyên dùng
            </div>
          </div>
          <div class="msp-item-card">
            <div class="msp-item-name">Standard</div>
            <div class="under-line bg-orange"></div>
            <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Gói đầy đủ các tính năng quản lý hệ thống nhiều CHXD</small></div>
            <div class="msp-btn-buy bg-orange">Liên hệ</div>
            <div class="msp-item-detail">
              <div class="msp-view-detail">
                <div class="item-detail">
                  <div class="item-text"><b>Quản lý danh mục dùng chung</b> </div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý đơn vị, theo mô hình phân cấp: Công ty/Chi nhánh /CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Danh mục dùng chung toàn công ty: Khách hàng, kho hàng, hợp đồng, hồ sơ giá …</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Quản lý hàng hóa tại CHXD</b> </div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý nhập hàng: Theo quy trình nhập</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý bán hàng theo ca</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý tồn kho hàng hóa: Tồn sổ sách và tồn thực tế</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Cảnh báo về tồn kho tối thiểu</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý hao hụt: Theo sổ sách và thực tế</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Giám sát hao hụt, rò rỉ, mất hàng</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Tiền hàng & Công nợ</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý tiền mặt tại cửa hàng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý tiền chuyển khoản/báo nợ, có</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý công nợ khách hàng, cửa hàng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Tính tuổi nợ, công nợ vượt quá hạn mức</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hóa đơn, ấn chỉ</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý lượng hóa đơn đã xuất theo từng khách hàng, theo từng thời điểm thay đổi giá</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Báo cáo xuất bán theo hóa đơn, công nợ theo hóa đơn</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Tích hợp hóa đơn điện tử: Cảnh báo khi hết dải hóa đơn</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hệ thống báo cáo, sổ sách</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Màn hình Dashboard của Cửa hàng trưởng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Hệ thống báo cáo chi tiết cho CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hệ thống báo cáo tổng hợp</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Màn hình Dashboard cho cán bộ quản lý</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Tổng hợp số liệu nhập hàng/xuất bán toàn Công ty</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Tổng hợp số liệu tồn kho/bể toàn Công ty</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Tổng hợp số liệu Tiền hàng/công nợ toàn Công ty</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hệ thống báo cáo quản trị</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Báo cáo ước doanh thu/sản lượng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Báo cáo hiệu quả kinh doanh toàn công ty/theo CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Báo cáo lãi lỗ/giá vốn toàn công ty/theo CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Quản lý bể & cột bơm</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Hồ sơ bể, lịch sử sửa chữa/bảo dưỡng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Hồ sơ cột bơm, lịch sử sửa chữa/bảo dưỡng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Hồ sơ pháp lý, cảnh báo hết hạn</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Tính năng khác</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý kiểm kê (Thay đổi giá/tháng/quý/năm)</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quản lý chi phí CHXD (chi phí thực tế, chi phí khoán)</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quy trình phê duyệt/điều động hàng hóa Công ty => Cửa hàng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Quy trình phê duyệt mở ca</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Cảnh báo tồn kho, trống bể hàng hóa</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Cảnh báo rủi ro công nợ khách hàng</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Tích hợp hệ thống</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-orange"></div>
                  <div class="item-text">Tích hợp hóa đơn điện tử</div>
                </div>
                <div class="item-detail">
                  <div class="item-text-delete">Tích hợp tự động hóa cột bơm</div>
                </div>
                <div class="item-detail">
                  <div class="item-text-delete">Tích hợp tự động hóa đo bể</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END-->
        <!--START-->
        <div class="msp-item-card-wrap module ItemCardModule shadow-5 mr-1" module="ItemCardModule" style="border-bottom-color: rgb(59, 80, 223);">
          <div class="msp-item-card">
            <div class="msp-item-name">Pro</div>
            <div class="under-line bg-blue"></div>
            <div class="msp-item-unit" style="color: rgb(0, 0, 0);"><small>Gói đầy đủ các tính năng quản lý hệ thống nhiều CHXD, tích hợp hệ thống Tự động hóa</small></div>
            <div class="msp-btn-buy bg-blue">Liên hệ</div>
            <div class="msp-item-detail">
              <div class="msp-view-detail">
                <div class="item-detail">
                  <div class="item-text"><b>Quản lý danh mục dùng chung</b> </div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý đơn vị, theo mô hình phân cấp: Công ty/Chi nhánh /CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Danh mục dùng chung toàn công ty: Khách hàng, kho hàng, hợp đồng, hồ sơ giá …</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Quản lý hàng hóa tại CHXD</b> </div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý nhập hàng: Theo quy trình nhập</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý bán hàng theo ca</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý tồn kho hàng hóa: Tồn sổ sách và tồn thực tế</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Cảnh báo về tồn kho tối thiểu</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý hao hụt: Theo sổ sách và thực tế</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Giám sát hao hụt, rò rỉ, mất hàng</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Tiền hàng & Công nợ</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý tiền mặt tại cửa hàng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý tiền chuyển khoản/báo nợ, có</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý công nợ khách hàng, cửa hàng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tính tuổi nợ, công nợ vượt quá hạn mức</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hóa đơn, ấn chỉ</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý lượng hóa đơn đã xuất theo từng khách hàng, theo từng thời điểm thay đổi giá</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Báo cáo xuất bán theo hóa đơn, công nợ theo hóa đơn</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tích hợp hóa đơn điện tử: Cảnh báo khi hết dải hóa đơn</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hệ thống báo cáo, sổ sách</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Màn hình Dashboard của Cửa hàng trưởng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Hệ thống báo cáo chi tiết cho CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hệ thống báo cáo tổng hợp</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Màn hình Dashboard cho cán bộ quản lý</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tổng hợp số liệu nhập hàng/xuất bán toàn Công ty</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tổng hợp số liệu tồn kho/bể toàn Công ty</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tổng hợp số liệu Tiền hàng/công nợ toàn Công ty</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Hệ thống báo cáo quản trị</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Báo cáo ước doanh thu/sản lượng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Báo cáo hiệu quả kinh doanh toàn công ty/theo CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Báo cáo lãi lỗ/giá vốn toàn công ty/theo CHXD</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Quản lý bể & cột bơm</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Hồ sơ bể, lịch sử sửa chữa/bảo dưỡng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Hồ sơ cột bơm, lịch sử sửa chữa/bảo dưỡng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Hồ sơ pháp lý, cảnh báo hết hạn</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Tính năng khác</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý kiểm kê (Thay đổi giá/tháng/quý/năm)</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quản lý chi phí CHXD (chi phí thực tế, chi phí khoán)</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quy trình phê duyệt/điều động hàng hóa Công ty => Cửa hàng</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Quy trình phê duyệt mở ca</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Cảnh báo tồn kho, trống bể hàng hóa</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Cảnh báo rủi ro công nợ khách hàng</div>
                </div>
                <div class="item-detail">
                  <div class="item-text"><b>Tích hợp hệ thống</b></div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tích hợp hóa đơn điện tử</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tích hợp tự động hóa cột bơm</div>
                </div>
                <div class="item-detail">
                  <div class="icon-item bc-blue"></div>
                  <div class="item-text">Tích hợp tự động hóa đo bể</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END-->
  </div>
</div>
