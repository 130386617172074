<!-- respond-->
<section class="section over-hidden pb-5 bg-gray">
  <div class="container">
    <div class="section__header">
      <h2 class="section__heading">Đánh giá của khách hàng</h2>
    </div>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <article class="fb-item">
          <div class='fb-img'>
            <img src="./assets/images/Trung.jpg" class='rounded-circle img-thumbnail'
              style="width:144px;height:144px;display:unset;" alt="" />
          </div>
          <div class="fb-content scroll">
            PVOIL Sài Gòn triển khai và ứng dụng hệ thống
            QLCH của NABIT từ năm 2019. Từ khi ứng dụng hệ thống, công tác quản lý của chúng tôi được chuẩn
            hóa, hiện đại và phục vụ tốt cho công tác quản trị của doanh nghiệp
          </div>
          <div class="fb-author">
            <span>Ông </span>
            <strong>Nguyễn Quang Trung</strong>
          </div>
          <div class="fb-position">
            Kế toán trưởng
            <br />Công ty PVOIL Sài Gòn
          </div>
        </article>
      </ng-template>
      <ng-template carouselSlide>
        <article class="fb-item">
          <div class='fb-img'>
            <img src="./assets/images/Mr.Dan.jpg" class='rounded-circle img-thumbnail'
              style="width:144px;height:144px;display:unset;" alt="" />
          </div>
          <div class="fb-content scroll">
            Giải pháp của NABIT là giái pháp chuyên cho ngành xăng dầu, đáp ứng được toàn bộ các nghiệp vụ đặc thù của
            ngành xăng dầu.
            Triển khai giải pháp quản lý của NABIT đã giúp cho PVOIL Hà Nội quản lý tốt hơn, giúp cho đội ngũ của PVOIL
            Hà Nội chuyên nghiệp hơn, phục vụ được khách hàng tốt hơn.
          </div>
          <div class="fb-author">
            <span>Ông </span>
            <strong>Lê Đức Dân</strong>
          </div>
          <div class="fb-position">
            Kế toán trưởng
            <br />Công ty PVOIL Hà Nội
          </div>
        </article>
      </ng-template>
      <ng-template carouselSlide>
        <article class="fb-item">
          <div class='fb-img'>
            <img src="./assets/images/MsDiep.jpg" class='rounded-circle img-thumbnail'
              style="width:144px;height:144px;display:unset;" alt="" />
          </div>
          <div class="fb-content scroll">
            Trước đây Công ty chúng tôi đã từng ứng dụng và triển khai hệ thống Quản lý cửa hàng bán lẻ xăng dầu, tuy nhiên sản phẩm được viết trên nền tảng công nghệ cũ, lạc hậu, quy trình nghiệp vụ bị fix cứng không linh động dẫn đến hiệu quả đem lại không cao. Mỗi lần thao tác hay kiểm tra số liệu thì bắt buộc phải xem trên máy tại Công ty. Ngay sau khi biết về sản phẩm BOS là sản phẩm do NABIT phát triển trên nền tảng công nghệ mới và được ứng dụng trong nhiều đơn vị PVOil thuộc Tổng công ty, tôi quyết định lựa chọn ngay và hy vọng rằng BOS sẽ giúp chúng tôi cải tiến công tác quản trị và điều hành doanh nghiệp.
          </div>
          <div class="fb-author">
            <span>Bà Trần Thị Diệp</span>
            <strong></strong>
          </div>
          <div class="fb-position">
            Trưởng phòng KDTH
            <br />Công ty Petromekong
          </div>
        </article>
      </ng-template>
      <ng-template carouselSlide>
        <article class="fb-item">
          <div class='fb-img'>
            <img src="./assets/images/customer.png" class='rounded-circle img-thumbnail'
              style="width:144px;height:144px;display:unset;" alt="" />
          </div>
          <div class="fb-content scroll">
            Triển khai giải pháp quản lý xăng dầu, giúp
            chúng tôi tiết kiệm đc tối đa chi phí, nhân công, tăng năng suất lao động. Giúp quy trình quản
            lý của Công ty được chuẩn hóa và hiệu quả.
          </div>
          <div class="fb-author">
            <span>Bà </span>
            <strong>Lê Ngọc Anh</strong>
          </div>
          <div class="fb-position">
            Kế toán trưởng
            <br />Công ty CP Xăng dầu Trang Đông
          </div>
        </article>
      </ng-template>
      <ng-template carouselSlide>
        <article class="fb-item">
          <div class='fb-img'>
            <img src="./assets/images/MrHong.jpg" class='rounded-circle img-thumbnail'
              style="width:144px;height:144px;display:unset;" alt="" />
          </div>
          <div class="fb-content scroll">
            Tôi rất hài lòng với đội ngũ nhân sự và phương án triển khai giải pháp của Công ty CP NABIT Việt Nam.
            Với đội ngũ dày dạn kinh nghiệm về triển khai giải pháp cho ngành xăng dầu, Công ty CP NABIT Việt Nam đã tư
            vấn và triển khai cho chúng tôi giải pháp đầy đủ, trọn vẹn về bài toán quản lý, giúp tăng năng suất và hiệu
            quả trong công việc
          </div>
          <div class="fb-author">
            <span>Ông </span>
            <strong>Hoàng Mạnh Hồng</strong>
          </div>
          <div class="fb-position">
            Trưởng phòng KDTH
            <br />Công ty PVOIL Hải Phòng
          </div>
        </article>
      </ng-template>
      <ng-template carouselSlide>
        <article class="fb-item">
          <div class='fb-img'>
            <img src="./assets/images/MrHop.jpg" class='rounded-circle img-thumbnail'
              style="width:144px;height:144px;display:unset;" alt="" />
          </div>
          <div class="fb-content scroll">
            Qua quá trình tìm hiểu thông tin về các đơn vị xây dựng và triển khai hệ thống quản lý cửa hàng bán lẻ xăng
            dầu, chúng tôi đã quyết định chọn NABIT là đối tác tư vấn và triển khai cho PVOil Phú Yên. Với kinh nghiệm
            dày dặn trong công tác tư vấn triển khai các sản phẩm trong ngành xăng dầu dầu khí, các chuyên gia của NABIT
            đã giúp chúng tôi xác định được vấn đề đang tồn tại trong công tác quản lý, xây dựng kế hoạch và biện pháp
            cải tiến. Các chuyên gia của NABIT đã không chỉ nhiệt tình và sát sao trong quá trình tư vấn triển khai xây
            dựng hệ thống, đối với quá áp dụng và cải tiến, chúng tối cũng luôn nhận được sự hỗ trợ nhiệt tình khi gặp
            khó khăn.
          </div>
          <div class="fb-author">
            <span>Ông Võ Nguyên Hợp</span>
            <strong></strong>
          </div>
          <div class="fb-position">
            Phó giám đốc
            <br />Công ty PVOIL Phú Yên
          </div>
        </article>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
