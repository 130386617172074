<div class="list-group">
  <a routerLink="/About" id="fivestar_1" class="list-group-item" [ngClass]="{'active': this.route.url==='/About'}">
    <i class="fa fa-file-text" style="font-size:20px"></i>&nbsp;Thư ngỏ
  </a>
  <a  routerLink="/Vision" id="fivestar_2" class="list-group-item" [ngClass]="{'active': this.route.url==='/Vision'}">
    <i class="fa fa-binoculars" style="font-size:20px"></i>&nbsp;Tầm nhìn
  </a>
  <a routerLink="/Structure" id="fivestar_3" class="list-group-item" [ngClass]="{'active': this.route.url==='/Structure'}">
    <i class="fa fa-sitemap" style="font-size:20px"></i>&nbsp;Sơ đồ tổ chức
  </a>
  <a routerLink="/Policy" id="fivestar_4" class="list-group-item" [ngClass]="{'active': this.route.url==='/Policy'}">
    <i class="fa fa-bar-chart" style="font-size:20px"></i>&nbsp;Chính sách hoạt động
  </a>
  <a routerLink="/Philosophy" id="fivestar_5" class="list-group-item" [ngClass]="{'active': this.route.url==='/Philosophy'}">
    <i class="fa fa-book " style="font-size:20px"></i>&nbsp;Triết lý kinh doanh
  </a>
</div>
